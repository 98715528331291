.MatContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.col{
    flex: 1;
    margin: auto 0;
    justify-content:center;
}

.spacer{
    height: 120px;
    width: 50px;
}

@media (max-width: 600px) {
    .MatContainer {
      display: block;
      .col {
        width: 100%;
        margin: 0 0 10px 0;
      }
    }
  }
