.QuantitySelector{
    height: 37px;
    width: 165px;
    position: relative;
    top: -57px;
    left: 80px;
}

.QuantitySelector pre{
    font-size: 20px;
    font-weight: bold;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    overflow: hidden;
    width: 100px;
    max-width: 130px;
    position: relative;
    text-align: right;
    padding-right: 5px;
    top: -35px;
    left: 30px;
}


.QuantitySelector .plusButton{
    height:26px;
    width: 26px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
    position: relative;
    top: -85px;
    left: 70px;
    color: white;
}

.QuantitySelector .clearButton{
    height:26px;
    width: 26px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
    position: relative;
    top: 5px;
    left: -70px;
    color: white;
}

.matNumField{
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
    color: white !important;
}