.MatComponent{
    width: 250px;
    height: 120px;
    background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.2));
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 106, 156, 0.4);
    margin: 0 auto 0 auto;
    margin-top: 12px;
    display: block;
}

.MatComponent h1{
    color: white;
    position: relative;
    top: -75px;
}

.matIcon{
    width: 70px;
    height: 70px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    position: relative;
    top: 5px;
    left: 5px;
}

.matIcon h5{
    position: relative;
    top: -30px;
    color: white;
}

.matImg{
    width: 50px;
    height: 50px;
}