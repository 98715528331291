.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

.header {
  background: white;
  width: 100%;
  height: 200px;
}
