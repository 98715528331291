.PriceSelector{
    width: 165px;
    height: 26px;
    max-width: 165px;
    max-height: 26px;
    position: relative;
    top: -65px;
    left: 80px;
    display: flex;
    justify-content: space-between;
}

.PriceSelector button{
    height:26px;
    width: 26px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
    color: white;
}

#wheelContainer{
    width: 113px;
    max-width: 113px;
    overflow: hidden;
    position: relative;
}

#priceWheel{
    Font-family: 'Courier New';
    font-weight: bold;
    position: absolute;
    /*
    transform: translate(9%, -60%);
    mask: linear-gradient(to right, transparent -8%, black 0%, black 6%, transparent 12%);
    -webkit-mask: linear-gradient(to right, transparent -8%, black 0%, black 6%, transparent 12%);
    */
    color: white;
}