.KeypadContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.Keypad{
    width: 50vw;
    height: 500px;
    border-radius: 10px;
}